import React, { createContext, useContext } from "react";

const analyticsContext = createContext({});

const useProvideAnalytics = () => {
  const trackPageView = (...args) => {
    if (typeof window.fathom === "undefined") {
      console.log("Fathom page view: ", ...args);

      return;
    }

    window.fathom.trackPageview(...args);

    console.log(`tracked page view: ${args[0].url}`);
  };

  const trackGoal = (id, value = 0) => {
    window.fathom.trackGoal(id, value);

    console.log(`tracked goal: ${id}`);
  };

  return {
    trackPageView,
    trackGoal,
  };
};

export const ProvideAnalytics = ({ children }) => {
  const analytics = useProvideAnalytics();

  return (
    <analyticsContext.Provider value={analytics}>
      {children}
    </analyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  return useContext(analyticsContext);
};
