import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import Callout from "./Callout";
import { ReactComponent as CompassIcon } from "../../images/compass.svg";
import { ReactComponent as GlobeIcon } from "../../images/globe.svg";
import { ReactComponent as MaginifyingGlassIcon } from "../../images/magnifying_glass.svg";
import { ReactComponent as PointerIcon } from "../../images/pointer.svg";
import { ReactComponent as VideoPlayIcon } from "../../images/video_play.svg";

const useStyles = makeStyles((theme) => ({
  component: {
    alignItems: "flex-end",
    backgroundColor: theme.palette.area.background,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(),
  },
  secondaryCallouts: {
    display: "grid",
    gridAutoFlow: "row",
    gridRowGap: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: (props) => theme.spacing(props.videoUrl ? 1 : 0),
    width: 450,
  },
  calloutIcon: {
    fill: theme.palette.area.primary,
    height: 32,
    width: 32,
  },
  videoPLayIcon: {
    fill: theme.palette.area.primary,
    height: 48,
    width: 48,
  },
}));

const Callouts = (props) => {
  const classes = useStyles({
    videoUrl: props.videoUrl,
  });

  return (
    <div className={classes.component}>
      <Callout
        border={false}
        icon={<PointerIcon className={classes.calloutIcon} />}
      >
        You can interact with the tables and figures in this report. Click on
        the links below to see our <Link to="/custom">Custom Reports</Link>.
      </Callout>

      <div className={classes.secondaryCallouts}>
        <Callout icon={<CompassIcon className={classes.calloutIcon} />}>
          <Link to="/custom">{props.landscapeDescription}</Link>
        </Callout>
        <Callout icon={<GlobeIcon className={classes.calloutIcon} />}>
          <Link to="/custom">{props.comparisonDescription}</Link>
        </Callout>
        <Callout
          icon={<MaginifyingGlassIcon className={classes.calloutIcon} />}
        >
          <Link to="/custom">{props.deepDiveDescription}</Link>
        </Callout>
      </div>

      {props.videoUrl ? (
        <Callout
          border={false}
          icon={<VideoPlayIcon className={classes.videoPLayIcon} />}
        >
          You can learn more about the results and how to use the Custom Reports
          in this{" "}
          <a
            href={props.videoUrl}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            video
          </a>
          .
        </Callout>
      ) : null}
    </div>
  );
};

Callouts.propTypes = {
  area: PropTypes.string.isRequired,
  landscapeDescription: PropTypes.string,
  comparisonDescription: PropTypes.string,
  deepDiveDescription: PropTypes.string,
  videoUrl: PropTypes.string,
};

Callouts.defaultProps = {
  landscapeDescription: "Landscape for a single country or region",
  comparisonDescription: "Compare across countries",
  deepDiveDescription: "Deep dive into one item",
};

export default Callouts;
