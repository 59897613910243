import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  component: {
    alignItems: "center",
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: theme.sideBySide.gap,
    gridAutoColumns: (props) => props.columnSizing,
    justifyItems: "center",
    width: "fit-width",
  },
  item: {
    width: "100%",
  },
}));

const SideBySide = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classNames(classes.component, props.className)}>
      <div className={classes.item}>
        {props.componentPosition === "left" ? props.component : props.children}
      </div>
      <div className={classes.item}>
        {props.componentPosition === "left" ? props.children : props.component}
      </div>
    </div>
  );
};

SideBySide.propTypes = {
  component: PropTypes.node.isRequired,
  componentPosition: PropTypes.oneOf(["left", "right"]),
  columnSizing: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

SideBySide.defaultProps = {
  componentPosition: "left",
  columnSizing: "1fr",
};

export default SideBySide;
