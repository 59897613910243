import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import SideBySide from "../Common/SideBySide";
import { enableCustomReports } from "../../constants";
import Callouts from "./Callouts";

const useStyles = makeStyles((theme) => ({
  component: {
    alignItems: "flex-start",
  },
  callouts: {
    display: enableCustomReports ? "block" : "none",
    width: 500,
  },
}));

const WithCallouts = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <SideBySide
      component={
        <div className={classes.callouts}>
          <Callouts {...rest} />
        </div>
      }
      componentPosition="right"
      columnSizing="auto"
      className={classes.component}
    >
      {children}
    </SideBySide>
  );
};

WithCallouts.propType = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default WithCallouts;
